.formBlock {
    position: relative;
    z-index: 1000;
    margin: auto;
    max-width: 600px;
    padding: 50px;
    border-radius: 30px;
    border: none;
    background-color: white;
    animation: openForm 0.7s;
}

@keyframes openForm {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.formBlock h2 {
    margin-bottom: 28px; 
    color: black;
    font-size: 32px;
    font-weight: 700;
    text-align: left;
}
.formBlock input {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    padding: 20px 32px;
    border: black solid 1px;
    border-radius: 16px;
    font-size: 20px;
}
.formBlock input::placeholder {
    color: black;
}
.formBlock .formButton {
    max-width: 600px;
}
.formButton {
    width: 100%;
    padding: 20px 0;
    border-radius: 16px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.confidential {
    margin-top: 16px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 21, 73, .6);
}
.confidential a {
    color:  rgba(0, 21, 73, .6);
}
.dimnessBlock {
    box-sizing: border-box;
    position: fixed;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100%;
    background-color: rgba(0, 0, 0, .6);
}
.dimnessBlock:before {
    min-height: 100%;
}

.closeBackground {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.nameBlock, .phoneBlock {
    position: relative;
}

/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .formBlock  {
        padding: 48px;
        border-radius: 30px;
    }
}
/* Mobile */
@media (max-width: 970px) {
    .formBlock  {
        padding: 38px 28px;
        border-radius: 20px;
    }
    .formBlock  input {
        padding: 20px 32px;
    }
    .formButton {
        padding: 16px 0;
        margin-top: 2px;
        font-size: 24px;
    } 
}
/* Experimental Mobile */
@media (max-width: 560px) {
    .formBlock h2 {
        font-size: 4.4vw;
        line-height: 120%;
        margin-bottom: 3.3vw;
    }
    .formBlock  {
        padding: 7vw 5.2vw;
        border-radius: 3.9vw;
    }
    .formBlock  input {
        padding: 3.7vw 5.7vw;
        margin-bottom: 3.0vw;
        border-radius: 3vw;
        font-size: 3.7vw;
    }
    .formButton {
        padding: 3vw 0;
        margin-top: 0.4vw;
        border-radius: 3vw;
        font-size: 4.4vw;
    } 
    .confidential {
        margin-top: 3vw;
        font-size: 2.6vw;
        line-height: 120%;
    }
}
/* Little Mobile */
@media (max-width: 580px) {
    /* .formBlock h2 {
        font-size: 24px;
    } */
}
/* Very little Mobile */
@media (max-width: 420px) {
    /* .formBlock  {
        padding: 32px 20px;
        min-width: 170px;
        border-radius: 16px;
    }
    .formBlock  h2 {
        width: 100%;
        margin-bottom: 22px;
        font-size: 20px;
        line-height: 19px;
    }
    .formBlock input {
        padding: 16px 20px;
        margin-bottom: 16px;
        font-size: 16px;
    }
    .formButton {
        margin-top: 0;
        font-size: 20px;
    }
    .confidential {
        width: 100%;
        font-size: 12px;
        line-height: 15px;
    } */
}