/* Background for index page */

.index {
    min-height: 100vh;
    background-attachment: fixed;
    background: 
        url("../../public/images/background.webp") no-repeat right center;
        background-color: #F6E7FF;
    background-size: cover;
}
/* Medium */
@media (max-width: 1750px) or (max-height: 950px) {
    .index {
        background-position-x: 70%;
    }
}
/* Little */
@media (max-width: 1200px) and (max-height: 800px) {
    .index {
        background: 
            url("../../public/images/little_background.webp") no-repeat right center;
        background-color: #F6E7FF;
        background-size: cover;
    }
}
/* Tablet */ 
@media (max-width: 900px) {
    .index {
        background-position: 55% 10vh;
    }
}

/* Mobile */ 
@media (max-width: 600px) { 
    .index {
        background:
            url("../../public/images/mobile_background.webp") no-repeat center bottom;
        background-color: #F6E7FF;
        background-size: cover;
    }
}
/* Short Mobile */ 
@media (max-width: 600px) and (max-height: 700px) { 
    .index {
        background-position-y: 0.2vh;
    }
}