.flex{
    display: flex;
    flex-direction: row;
}
.stretching  {
    flex: 1;
}

.index {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    flex-flow: row;
}
.offer h1, .blockHeader {
    color: #662CA2;
}
.offer h1 {
    display: inline-block;
    margin-bottom: 18px;
    margin-top: 115px;
    font-size: 70px;
    font-weight: 700;
    line-height: 77px;
}
.offer h1 p:first-child {
    margin-right: 127px;
}
.offer h1 p:last-child {
    text-align: end;
}
h2.blockHeader {
    margin-bottom: 46px;
    font-size: 40px;
    line-height: 44px;
    font-weight: 500;
    color: black;
}
h2.blockHeader::first-letter {
    letter-spacing: .1px;
}

button:hover {
    cursor: pointer;
}
/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 970px) {
    .offer h1 {
        font-size: 56px;
        line-height: 61px;
        margin-top: 95px;
        margin-bottom: 16px;
    }
    h2.blockHeader {
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 35px;
    }
}
/* Medium-Little */
@media (max-width: 1366px) or (max-height: 870px) {
    .offer h1 {
        margin-top: 95px;
        font-size: 42px;
        line-height: 46px;
    }
    h2.blockHeader {
        margin-bottom: 28px;
        font-size: 24px;
        line-height: 26px;
    }
}
/* Mobile */
@media (max-width: 570px) {
    .index {
        align-items: start;
    }
    .offer h1 {
        margin-top: 65px;
        margin-bottom: 16px;
        font-size: 42px;
        line-height: 46px;
    }
    h2.blockHeader {
        margin-bottom: 28px;
        font-size: 24px;
        line-height: 26px;
    }
}
/* Experiment Mobile */
@media (max-width: 570px) {
    .index {
        align-items: start;
    }
    .offer h1 {
        margin-top: 12vw;
        margin-bottom: 3vw;
        font-size: 7.8vw;
        line-height: 8.3vw;
    }
    h2.blockHeader {
        margin-bottom: 5.4vw;
        font-size: 4.4vw;
        line-height: 4.9vw;
    }
}
/* Little Mobile */
@media (max-width: 538px) {
    /* .offer h1 {
        font-size: 30px;
        line-height: 33px;
    }
    h2.blockHeader {
        font-size: 22px;
        line-height: 26px; 
    } */
}
/* Very little Mobile */
@media (max-width: 380px) {
    /* .offer h1 {
        font-size: 26px;
        line-height: 31px;
    }
    h2.blockHeader {
        font-size: 20px;
        line-height: 24px; 
    } */
}

/* Short Height */
@media (max-height: 720px) {
    /* .offer h1 {
        margin-top: 0;
    } */
}