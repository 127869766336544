/* Purple button */
.purpleButton {
    color: white;
    background-color: #662CA2;
}
.purpleButton:hover {
    background-color: #552686;
    cursor: pointer;
}
.purpleButton:active {
    background-color: #4a2175;
}
.purpleButton:disabled {
    cursor: default;
    background-color: #A484C6;
}

/* Closing cross button */
.closeButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .0);
}
.closeButton:hover {
    background-color: rgba(0, 0, 0, .1);
}
.closeButton:active {
    background-color: rgba(0, 0, 0, .2);
}
/* MEDIA */
/* Medium */
@media (max-width: 1670px) or (max-height: 920px) {
    .closeButton {
        top: 1rem;
        right: 1rem;
    }
}
/* Mobile */
@media (max-width: 970px) {
    .closeButton {
        top: 1rem;
        right: 1rem;
    }
}
/* Very little Mobile */
@media (max-width: 380px) {
    .closeButton {
        top: 0.3rem;
        right: 0.3rem;
    }
}